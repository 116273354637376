import { HomeOutlined, SettingOutlined, BarsOutlined,TeamOutlined, NotificationOutlined, ShopOutlined, PercentageOutlined, BellOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { Link } from "react-router-dom";

interface MenuListProps {
    darkTheme: boolean;
}


const MenuList: React.FC<MenuListProps> = ({ darkTheme }) => {
    const pageRedirect = (key: string) => {
        console.log('Page Key - ',key);
    }
    const items = [
        { label:<Link to="/">Inicio</Link>, key:'home', icon:<HomeOutlined/>},
        { label:<Link to="/passengers">Pasajeros</Link>, key:'passengers', icon:<TeamOutlined/>},
        { label:<Link to="/discounts">Descuentos</Link>, key:'discounts', icon:<PercentageOutlined/>},   
        { label:<Link to="/promotions">Promociones</Link>, key:'promotions', icon:<NotificationOutlined/>},  
        { label:<Link to="/notifications">Notificaciones</Link>, key:'notifications', icon:<BellOutlined/>},         
        { 
            label:'Menu Litos', key:'bar', icon:<ShopOutlined/>,  
            children:[
                { label:<Link to="/products/categories">Categorias</Link>, key:'categories'},
                { label:<Link to="/products">Productos</Link>, key:'products'},
            ]
        },
        { 
            label:'Archivos', key:'files', icon:<BarsOutlined/>,  
            children:[
                { label:<Link to="/questions">Preg. Frecuentes</Link>, key:'questions'},
                { label:<Link to="/connections">Acceso a Internet</Link>, key:'connections'},
            ]
        },
        { label:<Link to="/settings">Configuración</Link>, key:'settings', icon:<SettingOutlined/>}
    ]
    return (
        <Menu 
            theme={darkTheme ? 'dark' : 'light'}
            mode="inline"
            items={items}
            className="menu-bar"
            onClick={(e) => pageRedirect(e.key)}
        />
    )
}

export default MenuList;